<template>
  <div class="custom-input">
    <label v-if="$validate.DataValid(label)" class="input-label">{{ $t(label) }}</label>
    <v-textarea
      outlined
      single-line
      :dense="dense"
      :value="fieldValue"
      :disabled="disabled"
      :readonly="isReadOnly"
      :hide-details="hideDetails"
      :placeholder="$t(placeholder)"
      :rules="[v => (required ? $validate.required(v) || $t('message.formRequired') : true)]"
      :class="inputClass"
      @input="updateValue($event)"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'FormTextarea',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    fieldValue: {
      type: String | Number,
      required: true,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    customInputClass: {
      type: String,
      required: false,
      default: '',
    },
    dispatchUpdateOnChange: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isReadOnly() {
      return this.readonly || this.$store.getters.isLoading
    },
    inputClass() {
      let classObj = {
        'pointer-none': this.$store.getters.isLoading,
      }
      if (this.$validate.DataValid(this.customInputClass)) {
        const arr = this.customInputClass.split(' ')
        arr.forEach(name => {
          if (this.$validate.DataValid(name.trim())) {
            classObj[name.trim()] = true
          }
        })
      }

      return classObj
    },
  },
  data: () => ({}),
  methods: {
    updateValue(value) {
      this.searchText = value
      this.$emit('update:fieldValue', value)
      this.$emit('input-value', value)
      if (this.dispatchUpdateOnChange === true) {
        this.$store.dispatch('setDataIsUpdated', true)
      }
    },
  },
}
</script>
