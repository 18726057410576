<template>
  <div class="w-full">
    <v-btn class="upload-btn" text depressed @click="_triggerUploadFile()" :loading="uploading">{{ $t(uploadText) }}</v-btn>
    <v-file-input
      v-show="false"
      :id="fileInputKey"
      :accept="fileFormatStr"
      @change="getUploadFile($event)"
      :disabled="uploading"
    ></v-file-input>
    <ul class="file-list" v-if="files.length > 0">
      <template v-for="(item, i) in files">
        <li :key="i">
          <a :href="getFile(item.file)" :download="item.name" target="_blank">{{ item.name }}</a>
          <v-btn text depressed v-ripple="false" icon class="ml-4" x-small @click="removeFile(i)">
            <v-icon size="14" color="error">{{ icon.mdiClose }}</v-icon>
          </v-btn>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
export default {
  name: 'FormFileUploader',
  components: {},
  props: {
    fileInputKey: {
      type: String,
      default: 'upload_file',
      required: true,
    },
    files: {
      type: Array, // {name: '', file: ''}
      default: () => [],
      required: true,
    },
    acceptFormat: {
      type: String,
      default: 'imageWithPDF',
      required: true,
    },
    uploadText: {
      type: String,
      default: 'upload',
      require: false,
    },
    dispatchUpdateOnChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uploading() {
      return this.$store.getters.isUploading
    },
    fileFormatStr() {
      return this.$common.getAcceptFileFormat(this.acceptFormat).formatStr
    },
    fileFormatArr() {
      return this.$common.getAcceptFileFormat(this.acceptFormat).formatArr
    },
  },
  data: () => ({
    icon: {
      mdiClose,
    },
  }),
  methods: {
    _triggerUploadFile() {
      if (this.$validate.DataValid(this.fileInputKey) && document.getElementById(this.fileInputKey)) {
        document.getElementById(this.fileInputKey).click()
      }
    },
    removeFile(index) {
      const fileList = this.$common.duplicateData(this.files)
      fileList.splice(index, 1)
      this.$emit('update:files', fileList)
      if (this.dispatchUpdateOnChange === true) {
        this.$store.dispatch('setDataIsUpdated', true)
      }
    },
    async getUploadFile(file) {
      if (file !== undefined && file !== null) {
        const check = this.$validate.validateFileInput(file, this.fileFormatArr)
        if (this.$validate.DataValid(check)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: check,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } else {
          this.$store.dispatch('setUploadLoading', true)

          const reader = new FileReader()
          reader.onload = async event => {
            const fileType = file.type.split('/')[1]
            const payload = {
              upload_data: event.target.result,
              upload_file_type: fileType,
            }

            try {
              const data = await this.$Fetcher.UploadFile(payload)
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'message.uploadSuccess',
                type: 'success',
                refresh: false,
                redirect: '',
              })

              let fileList = this.$common.duplicateData(this.files)
              if (this.single) {
                fileList = [{ name: file.name, file: data }]
              } else {
                fileList.push({ name: file.name, file: data })
              }

              this.$emit('update:files', fileList)

              if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true)
              }
            } catch (err) {
              this.$common.error(err)
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'message.uploadFail',
                type: 'error',
                refresh: false,
                redirect: '',
              })
            } finally {
              this.$store.dispatch('setUploadLoading', false)
            }
          }
          reader.readAsDataURL(file)
        }
      }
    },

    getFile(file) {
      return `${this.$mediaPath}${file}`
    },
  },
}
</script>

<style lang="scss" scoped>
.file-list {
  margin-top: 10px;
  list-style: none;
  padding: 0;

  & > li {
    margin-top: 5px;

    & > a {
      text-decoration: none;
    }
  }
}
</style>
